table td {
    vertical-align: top;
    width: 50%;
    padding-left: 15px;
    padding-bottom: 0;
    padding-top: 18px;
}
table tr td:first-child {
    padding-top: 0;
    text-align: right;
    padding-left: 0;
    padding-right: 15px;
    border-right: 1px solid #fff;
    font-family: Fuggles, Calibri, Arial, sans-serif;
    font-size: 36px;
}
@media(max-width: 700px) {
    table tr td:first-child {
        width: 100px;
    }
    .xs-hide {
        display: none;
    }
}