
.navbar {
    /*background-color: #076970;*/
    background-color: #a4c8cc;
}
.navbar-light .navbar-brand {
    color: #fff;
    font-family: Fuggles, Calibri, Arial, sans-serif;
    font-size: 34px;
    padding: 0;
    line-height: 1;
}
.navbar-light .navbar-nav .nav-link {
    color: #fff;
    font-size: 14px;
}