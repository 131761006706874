.countdown-container {
    position: relative;
}
.countdown-wrapper {
    text-align: center;
    background: #a4c8cc;
    color: #fff;
    padding-top: 10px;
    padding-bottom: 20px;
}
.date {
    font-family: Fuggles, Calibri, Arial, sans-serif;
    font-size: 60px;
    line-height: 1;
    padding-bottom: 4px;
    display: inline-block;
}
.countdown {
    border-top: 1px solid #fff;
    display: inline-block;
    padding-top: 12px;
}
.days,
.hours,
.minutes,
.seconds {
    display: inline-block;
    padding: 0 4px;
    font-size: 18px;
    font-weight: 300;
}
.days > div,
.hours > div,
.minutes > div,
.seconds > div {
    line-height: 1;
    display: inline;
}
.time {
    font-size: 34px;
}

@media (min-width: 700px) {
    .countdown-wrapper {
        position: absolute;
        top: 15%;
        right: 5%;
        padding: 10px 20px 20px;
        border-radius: 4px;
    }
}
@media (min-width: 900px) {
    .countdown-wrapper {
        top: 15%;
        right: 8%;
    }
}
@media (min-width: 1000px) {
    .countdown-wrapper {
        top: 15%;
        right: 3%;
    }
    .date {
        font-size: 90px;
    }
    .days,
    .hours,
    .minutes,
    .seconds {
        padding: 0 4px;
        font-size: 24px;
    }
    .time {
        font-size: 50px;
    }
}
@media (min-width: 1200px) {
    .countdown-wrapper {
        top: 15%;
        right: 12%;
    }
}