@font-face {
    font-family: 'Fuggles';
    src: url("./assets/fonts/Fuggles-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}
body {
    font-family: Calibri, Arial, sans-serif;
    font-size: 18px;

}
.container {
    background: #a4c8cc;
    color: #fff;
    margin: 20px auto;
}
.container .link {
    color: #fff;
}

h1 {
    font-family: Fuggles, Calibri, Arial, sans-serif;
    font-size: 60px;
}
h2 {
    font-family: Fuggles, Calibri, Arial, sans-serif;
    font-size: 50px;
}
a {
    color: #a4c8cc;
}
@media (min-width: 600px) {
    h1 {
        font-size: 80px;
    }
}