.accordion-item {
    color: #333;
}
.accordion-item h2 {
    font-family: Calibri, Arial, sans-serif;
}
.accordion-item .accordion-button {
    font-size: 18px;
}
.accordion-item .accordion-button:focus {
    background: none;
    color: #a4c8cc;
    border: none;
    box-shadow: none;
}
.accordion-button:not(.collapsed) {
    background: none;
    color: #a4c8cc;
    box-shadow: none;
}
.accordion-body {
    font-size: 16px;
}